import { default as _91_46_46_46slug_93XOh4cis8EcMeta } from "/app/src/pages/[...slug].vue?macro=true";
import { default as _91_46_46_46slug_932yyFP2tdLAMeta } from "/app/src/pages/gift-cards/[...slug].vue?macro=true";
import { default as indexteSNmamrg1Meta } from "/app/src/pages/gift-cards/index.vue?macro=true";
import { default as purchaselrS5SkFD0uMeta } from "/app/src/pages/gift-cards/purchase.vue?macro=true";
import { default as successGZxiDvbQgVMeta } from "/app/src/pages/gift-cards/success.vue?macro=true";
import { default as indexdPwi6hPdMfMeta } from "/app/src/pages/index.vue?macro=true";
import { default as _91_46_46_46slug_93wvSy7tRLQRMeta } from "/app/src/pages/restaurants/[...slug].vue?macro=true";
import { default as indexfqjrnnEz67Meta } from "/app/src/pages/restaurants/index.vue?macro=true";
export default [
  {
    name: _91_46_46_46slug_93XOh4cis8EcMeta?.name ?? "slug",
    path: _91_46_46_46slug_93XOh4cis8EcMeta?.path ?? "/:slug(.*)*",
    meta: _91_46_46_46slug_93XOh4cis8EcMeta || {},
    alias: _91_46_46_46slug_93XOh4cis8EcMeta?.alias || [],
    redirect: _91_46_46_46slug_93XOh4cis8EcMeta?.redirect,
    component: () => import("/app/src/pages/[...slug].vue").then(m => m.default || m)
  },
  {
    name: _91_46_46_46slug_932yyFP2tdLAMeta?.name ?? "gift-cards-slug",
    path: _91_46_46_46slug_932yyFP2tdLAMeta?.path ?? "/gift-cards/:slug(.*)*",
    meta: _91_46_46_46slug_932yyFP2tdLAMeta || {},
    alias: _91_46_46_46slug_932yyFP2tdLAMeta?.alias || [],
    redirect: _91_46_46_46slug_932yyFP2tdLAMeta?.redirect,
    component: () => import("/app/src/pages/gift-cards/[...slug].vue").then(m => m.default || m)
  },
  {
    name: indexteSNmamrg1Meta?.name ?? "gift-cards",
    path: indexteSNmamrg1Meta?.path ?? "/gift-cards",
    meta: indexteSNmamrg1Meta || {},
    alias: indexteSNmamrg1Meta?.alias || [],
    redirect: indexteSNmamrg1Meta?.redirect,
    component: () => import("/app/src/pages/gift-cards/index.vue").then(m => m.default || m)
  },
  {
    name: purchaselrS5SkFD0uMeta?.name ?? "gift-cards-purchase",
    path: purchaselrS5SkFD0uMeta?.path ?? "/gift-cards/purchase",
    meta: purchaselrS5SkFD0uMeta || {},
    alias: purchaselrS5SkFD0uMeta?.alias || [],
    redirect: purchaselrS5SkFD0uMeta?.redirect,
    component: () => import("/app/src/pages/gift-cards/purchase.vue").then(m => m.default || m)
  },
  {
    name: successGZxiDvbQgVMeta?.name ?? "gift-cards-success",
    path: successGZxiDvbQgVMeta?.path ?? "/gift-cards/success",
    meta: successGZxiDvbQgVMeta || {},
    alias: successGZxiDvbQgVMeta?.alias || [],
    redirect: successGZxiDvbQgVMeta?.redirect,
    component: () => import("/app/src/pages/gift-cards/success.vue").then(m => m.default || m)
  },
  {
    name: indexdPwi6hPdMfMeta?.name ?? "index",
    path: indexdPwi6hPdMfMeta?.path ?? "/",
    meta: indexdPwi6hPdMfMeta || {},
    alias: indexdPwi6hPdMfMeta?.alias || [],
    redirect: indexdPwi6hPdMfMeta?.redirect,
    component: () => import("/app/src/pages/index.vue").then(m => m.default || m)
  },
  {
    name: _91_46_46_46slug_93wvSy7tRLQRMeta?.name ?? "restaurants-slug",
    path: _91_46_46_46slug_93wvSy7tRLQRMeta?.path ?? "/restaurants/:slug(.*)*",
    meta: _91_46_46_46slug_93wvSy7tRLQRMeta || {},
    alias: _91_46_46_46slug_93wvSy7tRLQRMeta?.alias || [],
    redirect: _91_46_46_46slug_93wvSy7tRLQRMeta?.redirect,
    component: () => import("/app/src/pages/restaurants/[...slug].vue").then(m => m.default || m)
  },
  {
    name: indexfqjrnnEz67Meta?.name ?? "restaurants",
    path: indexfqjrnnEz67Meta?.path ?? "/restaurants",
    meta: indexfqjrnnEz67Meta || {},
    alias: indexfqjrnnEz67Meta?.alias || [],
    redirect: indexfqjrnnEz67Meta?.redirect,
    component: () => import("/app/src/pages/restaurants/index.vue").then(m => m.default || m)
  }
]