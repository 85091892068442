import revive_payload_client_4sVQNw7RlN from "/app/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_KgADcZ0jPj from "/app/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_jmwsqit4Rs from "/app/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import payload_client_yVLowv6hDl from "/app/node_modules/nuxt/dist/app/plugins/payload.client.js";
import check_outdated_build_client_8vK7RkfGxZ from "/app/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import plugin_vue3_A0OWXRrUgq from "/app/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.mjs";
import components_plugin_KR1HBZs4kY from "/app/.nuxt/components.plugin.mjs";
import prefetch_client_5tzzN0oIVL from "/app/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import plugin_client_LcKgStRyi6 from "/app/node_modules/nuxt-gtag/dist/runtime/plugin.client.mjs";
import primevue_plugin_egKpok8Auk from "/app/.nuxt/primevue-plugin.mjs";
import plugin_client_XxbS7tGyhp from "/app/node_modules/@primevue/nuxt-module/dist/runtime/plugin.client.js";
import unocss_MzCDxu9LMj from "/app/.nuxt/unocss.mjs";
import chunk_reload_client_UciE0i6zes from "/app/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import plugin_1UohGbtF8v from "/app/node_modules/@pinia-plugin-persistedstate/nuxt/dist/runtime/plugin.mjs";
import error_handlers_client_8nO3pbtH3f from "/app/src/plugins/error-handlers.client.ts";
import facebook_pixel_client_O3HHaIqzMz from "/app/src/plugins/facebook-pixel.client.js";
import grecaptcha_client_bAGFPLfzve from "/app/src/plugins/grecaptcha.client.ts";
import sentry_client_KAXFuL2wum from "/app/src/plugins/sentry.client.ts";
import swiper_client_uDPQhOjmYM from "/app/src/plugins/swiper.client.ts";
import tiktok_pixel_client_2GiHS9C1Sw from "/app/src/plugins/tiktok-pixel.client.js";
import vue3_lottie_client_0evR0jGnoj from "/app/src/plugins/vue3-lottie.client.ts";
export default [
  revive_payload_client_4sVQNw7RlN,
  unhead_KgADcZ0jPj,
  router_jmwsqit4Rs,
  payload_client_yVLowv6hDl,
  check_outdated_build_client_8vK7RkfGxZ,
  plugin_vue3_A0OWXRrUgq,
  components_plugin_KR1HBZs4kY,
  prefetch_client_5tzzN0oIVL,
  plugin_client_LcKgStRyi6,
  primevue_plugin_egKpok8Auk,
  plugin_client_XxbS7tGyhp,
  unocss_MzCDxu9LMj,
  chunk_reload_client_UciE0i6zes,
  plugin_1UohGbtF8v,
  error_handlers_client_8nO3pbtH3f,
  facebook_pixel_client_O3HHaIqzMz,
  grecaptcha_client_bAGFPLfzve,
  sentry_client_KAXFuL2wum,
  swiper_client_uDPQhOjmYM,
  tiktok_pixel_client_2GiHS9C1Sw,
  vue3_lottie_client_0evR0jGnoj
]