<script lang="ts" setup>
  import { SITE_ENVIRONMENTS } from './constants/siteEnvironment'

  import ABCGrowE from '~/assets/fonts/ABCGrow-E.woff2?url'
  import ABCDiatypeRegular from '~/assets/fonts/ABCDiatype-Regular.woff2?url'
  import ABCDiatypeBold from '~/assets/fonts/ABCDiatype-Bold.woff2?url'

  if (process.server) {
    useHead({
      link: [
        {
          rel: 'preload',
          href: ABCGrowE,
          as: 'font',
          type: 'font/woff2',
          crossorigin: ''
        },
        {
          rel: 'preload',
          href: ABCDiatypeBold,
          as: 'font',
          type: 'font/woff2',
          crossorigin: ''
        },
        {
          rel: 'preload',
          href: ABCDiatypeRegular,
          as: 'font',
          type: 'font/woff2',
          crossorigin: ''
        }
      ]
    })
  }

  type LayoutName = 'with-ordering-app' | 'default'

  const $config = useRuntimeConfig()

  const layout: LayoutName =
    new Map<string, LayoutName>([
      [SITE_ENVIRONMENTS.AUSTRALIA, 'with-ordering-app'],
      [SITE_ENVIRONMENTS.INDONESIA, 'default']
    ]).get($config.public.siteEnvironment) || 'default'
</script>

<template>
  <NuxtLayout :name="layout">
    <NuxtPage />
  </NuxtLayout>
</template>

<style lang="scss">
  @import '@/styles/main.scss';

  body {
    background: var(--color-secondary-paper-lightest);
    font-family: var(--font-family-base);
  }
</style>
