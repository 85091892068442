import { defineNuxtPlugin } from '#app/nuxt'
import { LazyDatePicker, LazyFloatLabel, LazyIconField, LazyIftaLabel, LazyInputIcon, LazyInputNumber, LazyInputText, LazyTextarea, LazyToggleSwitch, LazyDivider, LazyStepper, LazyStepList, LazyStep, LazyStepPanels, LazyStepPanel, LazyDialog, LazySkeleton } from '#components'
const lazyGlobalComponents = [
  ["DatePicker", LazyDatePicker],
["FloatLabel", LazyFloatLabel],
["IconField", LazyIconField],
["IftaLabel", LazyIftaLabel],
["InputIcon", LazyInputIcon],
["InputNumber", LazyInputNumber],
["InputText", LazyInputText],
["Textarea", LazyTextarea],
["ToggleSwitch", LazyToggleSwitch],
["Divider", LazyDivider],
["Stepper", LazyStepper],
["StepList", LazyStepList],
["Step", LazyStep],
["StepPanels", LazyStepPanels],
["StepPanel", LazyStepPanel],
["Dialog", LazyDialog],
["Skeleton", LazySkeleton],
  
]

export default defineNuxtPlugin({
  name: 'nuxt:global-components',
  setup (nuxtApp) {
    for (const [name, component] of lazyGlobalComponents) {
      nuxtApp.vueApp.component(name, component)
      nuxtApp.vueApp.component('Lazy' + name, component)
    }
  }
})
